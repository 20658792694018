@import url('https://fonts.googleapis.com/css2?family=Anton&family=Lato:wght@100;300&family=Poppins:wght@200;300&family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url("https://site-assets.fontawesome.com/releases/v6.1.1/css/all.css");
/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
  }
body{
    background-color: #e9e9e9;
}
  /* Header Styling */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10% 15px 20%;
    background: linear-gradient(to right, black, maroon, black);
    color: white;
    position: relative;
    margin-top: 1.5%;
  }
    img.unilogo{
        width: 8%;
        position: absolute;
        left: 9%;
    }
  .header-logo h1 {
    font-size: 24px;
  }
  .contact{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .contact ol{
    display: flex;
    flex-direction: row;
  }
  .contact ol li{
    list-style-type: none;
    padding: 10px;
  }
  
  .nav-menu a {
    margin-left: 15px;
    color: white;
    text-decoration: none;
  }
  
  /* Main Content Styling */
  .content{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2% 5%;
    gap: 10%;
  }
  .chancellor-profile{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .chancellor-profile img{
    width: 100%;
  }
  .main-content h2{
    color: rgba(79, 4, 4, 0.851);
    font-size: 35px;
  }
  .main-content p{
    padding: 10px 0;
    line-height: 25px;
    color: rgba(79, 4, 4, 0.851);
    text-align: justify;
  }
  .chancellor-profile p{
    padding: 10px 0;
    text-align: center;
    color: rgba(79, 4, 4, 0.928);
    font-weight: 600;
  }
  
  /* Footer Styling */
  .footer {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2%;
  }
  .footer h3{
    color: rgba(79, 4, 4, 0.851);
    font-size: 40px;
  }
  .footer p{
    color: rgba(79, 4, 4, 0.851);
    text-align: justify;
    padding: 3px 0 10px 0;
  }
  
  .footer form input {
    padding: 10px;
    width: 250px;
    margin-right: 10px;
    background-color: black;
    color: white;
  }
  .footer form input::placeholder{
    color: white;
  }
  
  .footer form button {
    padding: 10px 20px;
    background-color: rgb(255, 255, 255);
    color: rgb(17, 140, 255);
    border: none;
  }
  .footer form button:hover{
    cursor: pointer;
  }
  
  @media screen and (max-width: 800px){
    .contact{
        display: none;
    }
    .nav-menu{
        display: none;
    }
    .header{
        display: flex;
        justify-content: center;
        background: none;
        color: rgba(79, 4, 4, 0.851);
        text-align: center;
    }
    .content{
        display: flex;
        flex-direction: column;
        margin: 4% 2%;
    }
    .chancellor-profile img {
        width: 50%;
    }
    .main-content h2 {
        text-align: center;
        font-size: 27px;
    }
    .footer {
        flex-direction: column;
        align-items: center;
        margin-left: 2%;
    }
    .main-content p{
        text-align: center;
      }
      img.unilogo {
        width: 15%;
    }
    .chancellor-profile {
        margin-top: 20px;
    }
    .footer h3 {
        color: rgba(79, 4, 4, 0.851);
        margin-bottom: 8px;
        font-size: 20px;
    }
  }
  @media screen and (max-width: 450px){
      .header-logo h1 {
        font-size: 18px;
    }
    .footer form input {
        padding: 10px;
        width: 218px;
    }
    .main-content p {
      font-size: 12px;
      line-height: 18px;
    }
    .main-content h2 {
      text-align: center;
      font-size: 22px;
    }
    img.unilogo {
      width: 20%;
    }
  }